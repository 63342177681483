import { useState, useEffect } from "react";
import superagent from "superagent";
import useAuth from "./useAuth";
import { HARDWIRED_URL } from "../config/api-client";

const isJSON = input => {
  try {
    JSON.parse(input);
    return true;
  } catch (err) {
    // Do nothing
  }
  return false;
};

export default function useFecExportFile(
  startDate,
  endDate,
  format,
  auxiliaryTypeValue,
  includeAttachments,
  realmId,
  clientFileId,
  convertAttachmentsToPdfs
) {
  const { user, getBearerToken } = useAuth();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const refreshItem = async () => {
      if (user && startDate && endDate && format) {
        try {
          const url = HARDWIRED_URL + "/fec-export";
          const response = await superagent
            .get(url)
            .set("Content-Type", "application/json")
            .set("Accept", "application/json")
            .set("Authorization", `Bearer ${await getBearerToken()}`)
            .query({
              startDate,
              endDate,
              format,
              auxiliaryTypeValue,
              includeAttachments,
              realmId,
              clientFileId,
              convertAttachmentsToPdfs
            })
            .send();

          console.log("Received response to FEC-Export call: ", response);
          if (isJSON(response.text)) {
            console.log("FEC Export file is JSON");
            setFile(JSON.parse(response.text));
            console.log("FEC Export file:", JSON.parse(response.text));
          } else {
            console.log("FEC Export file is NOT JSON");
            setFile(null);
          }
        } catch (err) {
          console.error(err);
          setError(err);
        }
      }
    };
    refreshItem().catch(console.error);
  }, [
    startDate,
    endDate,
    format,
    user,
    auxiliaryTypeValue,
    includeAttachments,
    getBearerToken,
    realmId,
    clientFileId
  ]);

  return [file, error];
}
