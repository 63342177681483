import React from "react";
import { Div, Text } from "atomize";

const Section = ({ title, children }) => (
  <Div
    m={"1rem"}
    p="1rem"
    d="flex"
    flexDir="column"
    border="solid 1px"
    borderColor="gray900"
  >
    <Div
      w="100%"
      m={{ b: "0.5rem" }}
      style={{ borderBotton: "solid 1px #8a94a6" }}
    >
      <Text textSize="subheader">{title}</Text>
    </Div>
    {children}
  </Div>
);

export default Section;
