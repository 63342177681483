import React from "react";
import { Div, Icon, Text } from "atomize";

export default function Pane({
  title,
  children,
  color = "info",
  icon = "InfoSolid",
  ...otherProps
}) {
  return (
    <Div
      d="flex"
      flexDir="column"
      rounded="xl"
      p="1rem"
      bg={`${color}200`}
      border="solid 1px"
      borderColor={`${color}700`}
      w="100%"
      {...otherProps}
    >
      <Div d="flex" align="center" m={{ b: "0.3rem" }}>
        <Icon name={icon} color={`${color}700`} m={{ r: "0.75rem" }} />
        <Text textWeight="500" textColor="black800" textSize="subheader">
          {title}
        </Text>
      </Div>
      <Div>
        <Text textColor="black400" textSize="paragraph">
          {children}
        </Text>
      </Div>
    </Div>
  );
}
