import { useState, useEffect } from "react";
import Promise from "bluebird";
import JsZip from "jszip";
import FileSaver from "file-saver";
import toArrayBuffer from "to-arraybuffer";
import useAuth from "./useAuth";
import moment from "moment";
import check from "check-types";
import queryString from "query-string";
import { HARDWIRED_URL } from "../config/api-client";
const Buffer = require("buffer/").Buffer;
const windows1252 = require("windows-1252");
const { PDFDocument } = require("pdf-lib");

const mergePdfs = async (...buffers) => {
  // Create a new document
  const doc = await PDFDocument.create();

  console.log("Asked to merge buffers: ", buffers);

  // Add each doc pages
  await Promise.map(
    buffers,
    async buffer => {
      try {
        // Load doc from buffer
        const toAdd = await PDFDocument.load(buffer);

        // Add pages
        const toAddPages = await doc.copyPages(toAdd, toAdd.getPageIndices());
        for (const page of toAddPages) {
          doc.addPage(page);
        }
      } catch (err) {
        console.error("Could not merge PDF buffers :", err);
      }
    },
    { concurrency: 3 }
  );

  // Return the document buffer
  return await doc.save();
};

const mergePdfBlobs = async blobs => {
  if (
    !check.nonEmptyArray(blobs) ||
    !check.nonEmptyArray(blobs.filter(b => !!b))
  ) {
    return null;
  }

  console.log("Asked to merge blobs: ", blobs);

  // Transform blobs to buffers
  const buffers = await Promise.map(
    blobs.filter(b => !!b),
    async blob => {
      return Buffer.from(await blob.arrayBuffer());
    }
  );

  // Merge PDF buffers
  const merged = await mergePdfs(...buffers);

  // Return merged blob
  return new Blob([merged], {
    type: "application/pdf"
  });
};

/*
const mergePdfBlobs = async blobs => {
  const doc = new pdf.Document();

  let addedDocs = 0;

  await Promise.map(
    blobs.filter(b => !!b),
    async blob => {
      try {
        const toAdd = new pdf.ExternalDocument(
          Buffer.from(await blob.arrayBuffer())
        );
        this.doc.addPagesOf(toAdd);
        addedDocs += 1;
      } catch (err) {
        console.error("Failed to parse or add external document to PDF: ", err);
      }
    },
    { concurrency: 3 }
  );

  if (addedDocs === 0) {
    return null;
  }

  return new Blob([await doc.asBuffer()], {
    type: "application/pdf"
  });
};
*/

const USE_S3_PROXY = true;

const downloadOne = async (url, asPdf = false, bearerToken = null) => {
  console.log("Downloading attachment from URL: ", url);
  const resp = await fetch(
    USE_S3_PROXY && !url.includes('s3-proxy')
      ? HARDWIRED_URL + "/s3-proxy?" + queryString.stringify({ url, asPdf })
      : url,
    bearerToken
      ? {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        }
      : undefined
  );
  return resp.blob();
};

const download = async (url, bearerToken = null) => {
  // URL is a string: download only
  if (check.nonEmptyString(url)) {
    return downloadOne(url, false, bearerToken);
  }

  // URL is an array of strings: download files as PDFs and merge them
  if (check.array(url)) {
    const blobs = await Promise.map(
      url,
      async u => downloadOne(u, true, bearerToken),
      {
        concurrency: 3
      }
    );

    if (
      !check.nonEmptyArray(blobs) ||
      !check.nonEmptyArray(blobs.filter(b => !!b))
    ) {
      return null;
    }

    return mergePdfBlobs(blobs.filter(b => !!b));
  }
};

const downloadByGroup = (
  attachments,
  files_per_group = 5,
  onProgress = () => {},
  bearerToken = null
) => {
  let blobForUrl = {};
  let downloaded = [];

  return Promise.map(
    attachments,
    async attachment => {
      const { tempURI } = attachment;
      console.log("Downloading attachment: ", attachment);
      let blob = null;
      try {
        if (check.nonEmptyString(tempURI) && blobForUrl[tempURI]) {
          // Use cached blobs if something was already downloaded from this URL
          blob = blobForUrl[tempURI];
        } else {
          // Else, download the file
          blob = await download(tempURI, bearerToken);
          if (check.nonEmptyString(tempURI)) {
            blobForUrl[tempURI] = blob;
          }
        }
      } catch (err) {
        console.error(err);
      }
      const out = { ...attachment, blob };
      downloaded.push(tempURI);
      onProgress(downloaded.length, attachments.length);
      return out;
    },
    { concurrency: files_per_group }
  );
};

const getReportBlob = fecExportFile => {
  const { reportData, reportEncoding } = fecExportFile;

  const reportContents = check.nonEmptyString(reportData)
    ? reportData
    : JSON.stringify(reportData, null, 4);

  let reportBuffer = Buffer.from(reportContents);
  if (reportEncoding === "windows-1252") {
    const binaryString = windows1252.encode(reportContents);
    reportBuffer = new Buffer(binaryString, "binary");
  }

  return new Blob([toArrayBuffer(reportBuffer)], {
    type: "application/octet-stream"
  });
};

const exportZip = (fecExportFile, attachmentsWithBlobs) => {
  const { reportName } = fecExportFile;

  const zip = JsZip();
  attachmentsWithBlobs
    .filter(a => !!a && !!a.blob)
    .forEach((attachmentWithBlob, i) => {
      const { filename, blob } = attachmentWithBlob;
      zip.file(filename, blob);
    });

  zip.file(reportName, getReportBlob(fecExportFile));

  return zip.generateAsync({ type: "blob" });
};

const getAttachments = fecExportFile => {
  if (fecExportFile && check.array(fecExportFile.attachments)) {
    return fecExportFile.attachments;
  }

  return [];
};

const zipFecExportFile = async (
  fecExportFile,
  onProgress = () => {},
  includeAttachments = true,
  bearerToken = null
) => {
  const attachments = includeAttachments ? getAttachments(fecExportFile) : [];
  onProgress(0, attachments.length);
  const attachmentsWithBlobs = check.nonEmptyArray(attachments)
    ? await downloadByGroup(
        attachments,
        10,
        onProgress,
        bearerToken
      )
    : [];

  console.log("attachmentsWithBlobs: ", attachmentsWithBlobs);
  return exportZip(fecExportFile, attachmentsWithBlobs);
};

export default function useZipProcess(
  fecExportFile,
  format,
  includeAttachments = true,
) {
  const { user, getBearerToken } = useAuth();
  const [zip, setZip] = useState(null);
  const [downloaded, setDownloaded] = useState(0);
  const [nAttachments, setNAttachments] = useState(0);
  const [error, setError] = useState(null);

  const triggerZipDownload = () => {
    if (!zip) {
      throw new Error("Cannot download zip as it doesn't exist yet");
    }

    const { zipName, reportName } = fecExportFile;
    const fileName = includeAttachments
      ? zipName || `${moment().format("YYYY-MM-DD_HH:mm")}_export.zip`
      : reportName || `${moment().format("YYYY-MM-DD_HH:mm")}_export.${format}`;
    return FileSaver.saveAs(zip, fileName);
  };

  useEffect(() => {
    const refreshItem = async () => {
      if (
        user &&
        check.nonEmptyObject(fecExportFile) &&
        check.array(fecExportFile.attachments)
      ) {
        try {
          setZip(
            includeAttachments
              ? await zipFecExportFile(
                  fecExportFile,
                  (downloaded, n) => {
                    setNAttachments(n);
                    setDownloaded(downloaded);
                  },
                  includeAttachments,
                  await getBearerToken()
                )
              : getReportBlob(fecExportFile)
          );
        } catch (err) {
          console.error(err);
          setError(err);
        }
      }
    };
    refreshItem().catch(console.error);
  }, [
    fecExportFile,
    format,
    includeAttachments,
    user,
    getBearerToken
  ]);

  return [zip ? triggerZipDownload : null, nAttachments, downloaded, error];
}
