import React, { useState } from "react";
import { Div, Text, Textarea, Button } from "atomize";
import check from "check-types";
import moment from "moment";
import useAuth from "../hooks/useAuth";
import firebase from "../lib/firebase";
import { useTranslation } from "react-i18next";

const FeedbackButton = ({ highlighted, children, ...otherProps }) => {
  return (
    <Div
      d="flex"
      align="center"
      justify="center"
      bg={highlighted ? "info900" : "gray500"}
      hoverBg={highlighted ? "info900" : "info500"}
      rounded="lg"
      m="0.5rem"
      p="1rem"
      textSize="title"
      flexGrow="1"
      cursor="pointer"
      {...otherProps}
    >
      {children}
    </Div>
  );
};

const FeedbackWidget = ({ query } = {}) => {
  const { t } = useTranslation("FeedbackWidget");
  const { user } = useAuth();
  const [highlighted, setHighLighted] = useState(null);
  const [comment, setComment] = useState(null);
  const [feedbackPushed, setFeedbackPushed] = useState(false);
  const disabled = !highlighted || !check.nonEmptyString(comment);
  const toPush = JSON.parse(
    JSON.stringify({
      feedback: highlighted,
      comment,
      query,
      date: moment().toISOString(),
      version: process.env.REACT_APP_VERSION
    })
  );

  const pushUserFeedback = () => {
    if (user && check.nonEmptyString(user.uid) && !disabled) {
      console.log("Pushing feedback to firebase: ", toPush);

      firebase
        .database()
        .ref("/users/" + user.uid + "/feedback")
        .push(toPush)
        .then(() => {
          setFeedbackPushed(true);
        })
        .catch(console.error);
    } else {
      console.log("Could not push feedback");
    }
  };

  return (
    <Div>
      {!feedbackPushed && (
        <>
          <Text>{t("Thanks for trying this tool!")}</Text>
          <Text>{t("Help us improve by giving us your feedback")}</Text>
          <Div m={{ t: "1rem" }}>
            <Text>{t("How did this tool work for you?")}</Text>
            <Div d="flex" flexDir="row">
              <FeedbackButton
                highlighted={highlighted === "happy"}
                onClick={() => setHighLighted("happy")}
              >
                😊
              </FeedbackButton>
              <FeedbackButton
                highlighted={highlighted === "neutral"}
                onClick={() => setHighLighted("neutral")}
              >
                😐
              </FeedbackButton>
              <FeedbackButton
                highlighted={highlighted === "unhappy"}
                onClick={() => setHighLighted("unhappy")}
              >
                🙁
              </FeedbackButton>
            </Div>
          </Div>
          <Div m={{ t: "1rem" }}>
            <Text>{t("What worked / didn't work ?")}</Text>
            <Textarea
              fontFamily="primary"
              placeholder={t("Give us your feedback")}
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </Div>
          <Div m={{ t: "1rem" }}>
            <Button
              bg="info700"
              textColor="white"
              disabled={disabled}
              onClick={pushUserFeedback}
              style={disabled ? { filter: "grayscale(80%)" } : {}}
            >
              {t("Send feedback")}
            </Button>
          </Div>
        </>
      )}

      {feedbackPushed && (
        <Text textWeight="600">{t("Thanks for your feedback! ")}</Text>
      )}
    </Div>
  );
};

export default FeedbackWidget;
