import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import shortHoveredPic from "../../assets/sign-in-with-intuit/Sign_in_transparent_btn_short_hover.svg";
import shortDefaultPic from "../../assets/sign-in-with-intuit/Sign_in_transparent_btn_short_default.svg";

import shortHoveredPicFR from "../../assets/sign-in-with-intuit/FR_Sign_in_transparent_btn_short_hover.svg";
import shortDefaultPicFR from "../../assets/sign-in-with-intuit/FR_Sign_in_transparent_btn_short_default.svg";

export default function SignInWithIntuitButton({
  children,
  size,
  ...otherProps
} = {}) {
  const { i18n } = useTranslation();
  const [hovered, setHovered] = useState(false);

  const getCurrentLng = () =>
    i18n.language || window.localStorage.i18nextLng || "";
  console.log("Current app language: " + getCurrentLng());

  let hoveredPic = shortHoveredPic;
  let defaultPic = shortDefaultPic;
  let width = 150;

  if (
    getCurrentLng()
      .toLowerCase()
      .includes("fr")
  ) {
    hoveredPic = shortHoveredPicFR;
    defaultPic = shortDefaultPicFR;
    width = 200;
  }

  return (
    <img
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      alt="Sign in with Intuit"
      style={{ width, height: "auto", cursor: "pointer" }}
      src={hovered ? hoveredPic : defaultPic}
      {...otherProps}
    />
  );
}
