import React from "react";
import { Div } from "atomize";
import NavBar from "./NavBar";

function RawPageLayout({ onBack, footer, children, ...otherProps }) {
  return (
    <>
      <NavBar onBack={onBack} />
      <Div
        w="100%"
        h="100%"
        minH="100vh"
        d="flex"
        flexDir="column"
        bg="gray300"
        p={{ t: "4.5rem" }}
      >
        <Div
          w="100%"
          d="flex"
          flexDir="column"
          flexGrow="1"
          p={{ t: "2rem" }}
          align="center"
          justify="flex-start"
          {...otherProps}
        >
          {children}
        </Div>
        {footer && (
          <Div w="100%" d="flex" flexDir="column">
            {footer}
          </Div>
        )}
      </Div>
    </>
  );
}

export default RawPageLayout;
