import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../lib/firebase";
import LoadingPage from "./LoadingPage";
import AuthContext from "../contexts/AuthContext";
import queryString from "query-string";
import check from "check-types";
import useQuery from "../hooks/useQuery";
import { HARDWIRED_URL } from "../config/api-client";

// Effect to listen to auth state changes
const useAuthListener = () => {
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const onAuthStateChange = user => {
      if (!user) {
        if (currentUser) {
          console.log("[🔒 Auth] We've been logged out");
        } else {
          console.log("[🔒 Auth] Currently not authenticated");
        }
      } else {
        console.log(
          `[🔒 Auth] Currently logged in as ${user.email} (${user.uid})`,
          user
        );
      }

      setCurrentUser(user);
      setAuthLoading(false);
    };

    // Mount
    firebase.auth().onAuthStateChanged(onAuthStateChange);

    // Unmount function
    const unmount = () => {
      firebase.auth().offAuthStateChange(onAuthStateChange);
    };

    return unmount;
  }, []);

  return [currentUser, authLoading];
};

// Auth provider
export default function AuthProvider({ children }) {
  const history = useHistory();
  const [user, authLoading] = useAuthListener();

  // Detect incoming tokens in query
  const query = useQuery();
  const { auth } = query;

  let content = children;

  if (check.nonEmptyString(auth)) {
    // If a token is detected, login via Firebase
    firebase
      .auth()
      .signInWithCustomToken(auth)
      .catch(console.error)
      .finally(() => {
        // Once we're logged in, remove token from URL
        window.location.href =
          window.location.origin +
          window.location.pathname +
          "?" +
          queryString.stringify({ ...query, auth: undefined });
      });

    // Display a loading page in full screen while logging in
    content = <LoadingPage />;
  } else if (authLoading) {
    // Until user is null or set, we display loading page
    content = <LoadingPage />;
  }

  // Auth functions
  const login = () => {
    window.location.href =
      HARDWIRED_URL +
      "/auth?" +
      queryString.stringify({
        ...(query || {}),
        from: window.location.origin + window.location.pathname,
        authError: undefined
      });
  };

  const logout = async (redirect = true) => {
    // Move back to root page
    if (redirect) {
      history.push("/" + window.location.search);
    }

    // Trigger logout
    return firebase.auth().signOut();
  };

  const getBearerToken = async () => {
    return firebase.auth().currentUser.getIdToken(true);
  };

  // Content
  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        getBearerToken: user ? getBearerToken : async () => "NONE"
      }}
    >
      {content}
    </AuthContext.Provider>
  );
}
