import React from "react";
import { Div, Text } from "atomize";
import { useTranslation } from "react-i18next";
import RawPageLayout from "./RawPageLayout";

const LanguageButton = ({ ln }) => {
  const { i18n } = useTranslation("PageLayout");
  const getCurrentLng = () =>
    i18n.language || window.localStorage.i18nextLng || "";
  console.log("Current app language: " + getCurrentLng());

  if (getCurrentLng().includes(ln)) {
    return <Text textWeight="600">{ln.toUpperCase()}</Text>;
  } else {
    return (
      <Text
        textWeight="600"
        cursor="pointer"
        onClick={() => i18n.changeLanguage(ln)}
        textColor="info700"
        textDecor="underline"
      >
        {ln.toUpperCase()}
      </Text>
    );
  }
};

function PageLayout({ onBack, children, ...otherProps }) {
  const { t } = useTranslation("PageLayout");

  return (
    <RawPageLayout
      onBack={onBack}
      footer={
        <Div
          d="flex"
          flexDir="row"
          align="center"
          justify="space-between"
          style={{ borderTop: "solid 1px #DDD" }}
          w="100%"
          p={{ x: "1rem" }}
          m={{ t: "1rem" }}
        >
          <Div d="flex" flexDir="row" align="center">
            {t("Language")}:&nbsp;
            <LanguageButton ln={"fr"} />
            &nbsp;-&nbsp;
            <LanguageButton ln={"en"} />
          </Div>
          <Div d="flex" flexDir="row" align="center">
            v.{process.env.REACT_APP_VERSION}
          </Div>
        </Div>
      }
      {...otherProps}
    >
      {children}
    </RawPageLayout>
  );
}

export default PageLayout;
