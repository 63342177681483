import React, { useState } from "react";
import { Icon, Button } from "atomize";

export default function BasicButton({
  loading = false,
  disabled = false,
  prefix = null,
  icon = null,
  secondary = false,
  color = "info",
  onClick,
  children,
  ...otherProps
}) {
  const [actionPending, setActionPending] = useState(false);
  const [actionSuccess, setActionSuccess] = useState(null);

  let bg = `${color}700`;
  let textColor = "white";
  let hoverTextColor = "white";
  let hoverBg = `${color}800`;
  let border = null;
  let borderColor = null;
  let hoverBorderColor = null;

  if (secondary) {
    bg = "white";
    textColor = `${color}700`;
    hoverTextColor = `${color}900`;
    hoverBg = `${color}200`;
    border = "1px solid";
    borderColor = `${color}700`;
    hoverBorderColor = `${color}900`;
  }

  if (disabled || loading || actionPending) {
    hoverBg = null;
  }

  let prefixIcon = prefix || null;
  if (!prefixIcon) {
    if (icon) {
      prefixIcon = (
        <Icon name={icon} size="16px" color="white" m={{ r: "0.5rem" }} />
      );
    } else if (loading || actionPending) {
      prefixIcon = (
        <Icon name="Loading" size="16px" color="white" m={{ r: "0.5rem" }} />
      );
    } else if (actionSuccess === true) {
      // prefixIcon = <Icon name="Checked" size="16px" color="white" m={{ r: '0.5rem' }} />;
    } else if (actionSuccess === false) {
      // prefixIcon = <Icon name="Cross" size="16px" color="white" m={{ r: '0.5rem' }} />;
    }
  }

  return (
    <Button
      p={{ x: "2rem" }}
      shadow="3"
      hoverShadow="4"
      disabled={disabled || loading || actionPending}
      prefix={prefixIcon}
      onClick={
        onClick
          ? () => {
              setActionPending(true);
              setActionSuccess(null);
              Promise.resolve(onClick())
                .then(() => setActionSuccess(true))
                .catch(() => setActionSuccess(false))
                .finally(() => setActionPending(false));
            }
          : null
      }
      {...{
        bg,
        textColor,
        hoverTextColor,
        hoverBg,
        border,
        borderColor,
        hoverBorderColor
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
