import React from "react";
import { Icon } from "atomize";
import RawPageLayout from "./UI/RawPageLayout";

const LoadingPage = () => (
  <RawPageLayout align="center" justify="center">
    <Icon name="Loading" size={"4.5rem"} color="black100" />
  </RawPageLayout>
);

export default LoadingPage;
