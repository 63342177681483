import { Div, Text, Icon } from "atomize";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import useFecExportFile from "./hooks/useFecExportFile";
import useZipProcess from "./hooks/useZipProcess";
import LoadingPage from "./components/LoadingPage";
import WelcomePage from "./components/WelcomePage";
import Section from "./components/UI/Section";
import Button from "./components/UI/Button";
import PageLayout from "./components/UI/PageLayout";
import FeedbackWidget from "./components/FeedbackWidget";
import useAuth from "./hooks/useAuth";
import useQuery from "./hooks/useQuery";
import useCompanies from "./hooks/useCompanies";
import check from "check-types";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

const toBool = (val, defaultValue) => {
  if (check.boolean(val)) {
    return val;
  }

  if (check.nonEmptyString(val)) {
    if (["true", "1", "yes"].includes(val.trim().toLowerCase())) {
      return true;
    } else if (["false", "0", "no"].includes(val.trim().toLowerCase())) {
      return false;
    }
  }

  return defaultValue;
};

const ExportPage = () => {
  const { t } = useTranslation("ExportPage");
  const history = useHistory();

  // Auth
  const { user, login, logout } = useAuth();
  // Query parsing
  const query = useQuery();
  const {
    startDate,
    endDate,
    format,
    tool,
    clientFileId,
    authError,
    auxiliaryTypeValue,
    includeAttachments: rawIncludeAttachments,
    convertAttachmentsToPdfs: rawConvertAttachmentsToPdfs,
    realmId
  } = query;

  const includeAttachments = toBool(rawIncludeAttachments, true);
  const convertAttachmentsToPdfs = toBool(rawConvertAttachmentsToPdfs, true);

  // Hooks
  const [companies, selectedCompany] = useCompanies(realmId);
  const [fecExportFile, fecExportFileError] = useFecExportFile(
    startDate,
    endDate,
    format,
    auxiliaryTypeValue,
    includeAttachments,
    realmId,
    clientFileId,
    convertAttachmentsToPdfs
  );
  const [downloadZip, nAttachments, downloaded, zipError] = useZipProcess(
    fecExportFile,
    format,
    includeAttachments,
  );

  // Make sure params are all set
  if (!check.nonEmptyString(format) && check.nonEmptyString(tool)) {
    // If tool is not supported, delete it
    if (
      ![
        "ACD_CSV",
        "CEGID_QUADRA",
        "CEGID_EXPERT",
        "SAGE_GENERATION_EXPERT",
        "SAGE_GENERATION_EXPERT_RB",
        "ISACOMPTA_CONNECT",
        "IBIZA",
        "IBIZA_FEC",
        "GENERIC_CSV"
      ].includes(tool)
    ) {
      return (
        <Redirect
          to={"/?" + queryString.stringify({ ...query, tool: undefined })}
        />
      );
    }
    // Else, calculate format from tool if necessary
    let calculatedFormat = "json";
    if (tool.includes("CEGID")) {
      calculatedFormat = "tra";
    } else if (tool === "SAGE_GENERATION_EXPERT") {
      calculatedFormat = "sage.fec.txt";
    } else if (tool === "SAGE_GENERATION_EXPERT_RB") {
      calculatedFormat = "sage.fec.csv";
    } else if (tool === "ISACOMPTA_CONNECT") {
      calculatedFormat = "ecr";
    } else if (tool === "IBIZA") {
      // calculatedFormat = "ibiza.csv";
      calculatedFormat = "ibiza.fec.txt";
    } else if (tool === "IBIZA_FEC") {
      calculatedFormat = "ibiza.fec.txt";
    } else if (tool === "GENERIC_CSV") {
      calculatedFormat = "csv";
    } else if (tool === "ACD_CSV") {
      calculatedFormat = "csv";
    }

    return (
      <Redirect
        to={
          window.location.pathname +
          "?" +
          queryString.stringify({
            ...query,
            format: calculatedFormat
          })
        }
      />
    );
  }

  if (
    !check.nonEmptyString(startDate) ||
    !check.nonEmptyString(endDate) ||
    !check.nonEmptyString(format)
  ) {
    return <Redirect to={"/?" + queryString.stringify(query)} />;
  }

  // If we're exporting to IsaCompta, ask for clientFileId
  if (!check.nonEmptyString(clientFileId) && format === "ecr") {
    return (
      <Redirect
        to={"/?" + queryString.stringify({ ...query, onlyClientFileId: true })}
      />
    );
  }

  // Auth
  if ((!user || !check.nonEmptyString(realmId)) && !authError) {
    login();
    return null;
  }

  const authErrorText = t("There was an error authenticating");
  if (authError) {
    return authErrorText;
  }

  // We are authed

  const unknownCompanyText = t("Unknown company");
  const fecErrorText = t("We couldn't export your transactions");
  const fecErrorSubText = t("Please check your internet connection");
  const tryAgainText = t("Try again");
  const zipErrorText = t("Error zipping your report");

  // If FEC produced an error, display error page
  if (fecExportFileError) {
    return (
      <PageLayout align="center" justify="center">
        <Icon name="Alert" size="42px" m={{ b: "1rem" }} />
        <Text textWeight="600" textSize="subheader">
          {fecErrorText}
        </Text>
        <Text>{fecErrorSubText}</Text>

        <Button
          m={{ t: "1rem" }}
          icon="Refresh"
          onClick={async () => {
            // Erase company auth info from server (it probably is bad)
            try {
              await selectedCompany.disconnect();
            } catch (e) {
              console.error(e);
            }

            // Logout user to make sure we get a new auth token
            try {
              await logout(false);
            } catch (e) {
              console.error(e);
            }

            // Reload page to retry flow
            window.location.reload();
          }}
        >
          {tryAgainText}
        </Button>
      </PageLayout>
    );
  }

  // Else, wait for FEC epxort file
  if (!fecExportFile) {
    return <LoadingPage />;
  }

  const onBack = () => {
    history.push(
      "/?" +
        queryString.stringify({
          startDate,
          endDate,
          format,
          tool,
          realmId,
          clientFileId
        })
    );
  };

  // We have the file, display it:
  return (
    <PageLayout align="center" justify="flex-start" onBack={onBack}>
      <Div w="100%" maxW="800px" d="flex" flexDir="column">
        <Section title={t("Your company")}>
          {fecExportFile.companyInfo?.CompanyInfo?.CompanyName ||
            "<" + unknownCompanyText + ">"}
        </Section>

        <Section title={t("Start date")}>{startDate}</Section>

        <Section title={t("End date")}>{endDate}</Section>

        {includeAttachments && (
          <>
            <Section title={t("Attachements found")}>
              {nAttachments || 0}
            </Section>

            <Section title={t("Progress")}>
              {t("Downloaded")}: {downloaded} / {nAttachments || 0}{" "}
              {nAttachments > 0 &&
                " (" + Math.round((100 * downloaded) / nAttachments) + "%)"}
            </Section>
          </>
        )}

        {zipError && <Div>{zipErrorText}</Div>}

        {!zipError && downloadZip && (
          <>
            <Section title={t("Output")}>
              <Div w="fit-content">
                <Button icon="Download" color="success" onClick={downloadZip}>
                  {t("Download")}
                </Button>
              </Div>
            </Section>

            <Section title={t("Feedback")}>
              <FeedbackWidget query={query} />
            </Section>

            <Div m="1rem" w="100%" d="flex">
              <Button icon="LeftArrow" color="gray" onClick={onBack}>
                {t("Back")}
              </Button>
            </Div>
          </>
        )}
      </Div>
    </PageLayout>
  );
};

function App() {
  // Handle language switch management from query
  const { i18n } = useTranslation();
  const query = useQuery();
  const { ln } = query;
  const getCurrentLng = () =>
    i18n.language || window.localStorage.i18nextLng || "";
  console.log("Current app language: " + getCurrentLng());
  if (
    check.nonEmptyString(ln) &&
    ["fr", "en"].includes(ln) &&
    getCurrentLng() !== ln
  ) {
    i18n.changeLanguage(ln);
  }

  // Display app
  return (
    <Div minW="100vw" minH="100wh">
      <Switch>
        {/* Redirect QuickBooks-specific endpoint to main app */}
        <Route path="/quickbooks/redirect/start-export">
          <Redirect to={"/export" + window.location.search} />
        </Route>

        <Route path="/export">
          <ExportPage />
        </Route>

        <Route path="/">
          <WelcomePage />
        </Route>
      </Switch>
    </Div>
  );
}

export default App;
