import superagent from "superagent";
import useUserData from "./useUserData";
import useAuth from "./useAuth";
import check from "check-types";
import moment from "moment";
import { HARDWIRED_URL } from "../config/api-client";

const useCompanies = currentRealmId => {
  const [data] = useUserData();
  const { getBearerToken } = useAuth();

  console.log("Received data in useCompanies() --> ", data);

  if (!data || !data.companies) {
    console.log("useCompanies --> Invalid data. Returning []");
    return [];
  }

  const disconnectCompany = async realmId => {
    const url = HARDWIRED_URL + "/auth/disconnect-company";
    await superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set("Authorization", `Bearer ${await getBearerToken()}`)
      .query({
        realmId
      })
      .send();
  };

  const companies = Object.keys(data.companies).map(key => {
    const { CompanyInfo, accessGrantedUntil } = data.companies[key];

    return {
      realmId: key,
      name: CompanyInfo?.CompanyName,
      accessGrantedUntil,
      expired: moment(accessGrantedUntil).valueOf() < moment().valueOf(),
      data: CompanyInfo,
      disconnect: async () => disconnectCompany(key),
      selected: check.nonEmptyString(currentRealmId) && currentRealmId === key
    };
  });

  const selectedCompany =
    companies.find(c => c.selected) ||
    (check.nonEmptyArray(companies) ? companies[0] : null);

  console.log("Retrieved companies: ", companies, selectedCompany);

  return [companies, selectedCompany];
};

export default useCompanies;
