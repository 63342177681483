import React from "react";
import { Div, Image, Icon } from "atomize";
import useAuth from "../../hooks/useAuth";
import qboLogo from "../../assets/qb-logo-horizontal-preferred.svg";

function Navbar({ onBack }) {
  const { user, logout } = useAuth();

  return (
    <>
      <Div
        d="flex"
        shadow="2"
        h="4.5rem"
        w="100%"
        pos="fixed"
        top="0"
        m="0"
        left="0"
        flexDir="row"
        align="center"
        bg="white"
        p={{ l: "1rem", r: "1rem" }}
        bottom="0"
        style={{ zIndex: 1000 }}
        justify="space-between"
      >
        <Div d="flex" align="center">
          {onBack && (
            <Icon
              name="LeftArrow"
              size="24px"
              color="black900"
              m={{ r: "0.5rem" }}
              cursor="pointer"
              onClick={onBack}
            />
          )}
          <Image src={qboLogo} h="4rem" w="auto" />
          {/*
            <Text textSize="title" textWeight="600" textColor="black900">
              Exports-Comptables.com
            </Text>
            */}
        </Div>
        {!!user && (
          <Icon
            name="Logout"
            size="24px"
            color="black900"
            m={{ r: "0.5rem" }}
            cursor="pointer"
            onClick={logout}
          />
        )}
      </Div>
    </>
  );
}

export default Navbar;
