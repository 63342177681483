import { createContext } from "react";

const AuthContext = createContext({
  user: undefined,
  login: () => {},
  logout: () => {},
  getBearerToken: async () => "NONE"
});
AuthContext.displayName = "AuthContext";

export default AuthContext;
