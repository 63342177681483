// This file initializes locize/i18next tooling

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import LastUsed from "locize-lastused";
import { locizePlugin } from "locize";
import { projectId, apiKey, referenceLng } from "../config/locize.json";
import * as resources from "../locales";

const IS_PRODUCTION = window.location.host === "exports-comptables.com";

// In production, load resources from locales files
if (IS_PRODUCTION) {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      debug: true,
      saveMissing: true, // window.location.origin.includes('localhost'),
      updateMissing: true, // window.location.origin.includes('localhost'),
      fallbackLng: "en",
      nsSeparator: false,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      },
      resources
    });
} else {
  // In development, use locize
  // Documentation about locize and i18next: https://github.com/locize/react-tutorial
  const locizeOptions = {
    projectId,
    apiKey,
    referenceLng,
    allowedAddOrUpdateHosts: [
      "localhost",
      "qbo-exports-comptables-com.vercel.app",
      "exports-comptables-com-smarco-seafront.vercel.app"
    ]
  };

  i18n
    // i18next-locize-backend
    // loads translations from your project, saves new keys to it (saveMissing: true)
    // https://github.com/locize/i18next-locize-backend
    .use(Backend)
    // locize-lastused
    // sets a timestamp of last access on every translation segment on locize
    // -> safely remove the ones not being touched for weeks/months
    // https://github.com/locize/locize-lastused
    .use(LastUsed)
    // locize-editor
    // InContext Editor of locize
    .use(locizePlugin)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
      debug: true,
      saveMissing: true, // window.location.origin.includes('localhost'),
      updateMissing: true, // window.location.origin.includes('localhost'),
      backend: locizeOptions,
      locizeLastUsed: locizeOptions,
      fallbackLng: "en",
      nsSeparator: false,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      }
    });
}
