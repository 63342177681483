import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "atomize";

import shortDefaultPicEN from "../../assets/connect-to-quickbooks/C2QB_green_btn_short_default.svg";
import shortHoveredPicEN from "../../assets/connect-to-quickbooks/C2QB_green_btn_short_hover.svg";
import shortDefaultPicFR from "../../assets/connect-to-quickbooks/C2QB_FR_green_btn_short_default.svg";
import shortHoveredPicFR from "../../assets/connect-to-quickbooks/C2QB_FR_green_btn_short_hover.svg";

export default function ConnectToQuickBooksButton({
  children,
  size,
  ...otherProps
} = {}) {
  const { i18n } = useTranslation();
  const [hovered, setHovered] = useState(false);

  const getCurrentLng = () =>
    i18n.language || window.localStorage.i18nextLng || "";
  console.log("Current app language: " + getCurrentLng());

  let hoveredPic = shortHoveredPicEN;
  let defaultPic = shortDefaultPicEN;

  if (
    getCurrentLng()
      .toLowerCase()
      .includes("fr")
  ) {
    hoveredPic = shortHoveredPicFR;
    defaultPic = shortDefaultPicFR;
  }

  return (
    <Image
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      alt="Sign in with Intuit"
      style={{ width: "163px", height: "auto", cursor: "pointer" }}
      src={hovered ? hoveredPic : defaultPic}
      {...otherProps}
    />
  );
}
