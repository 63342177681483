import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Div, Text, Switch, Label, Icon, Input } from "atomize";
import PageLayout from "./UI/PageLayout";
import SignInWithIntuitButton from "./UI/SignInWithIntuitButton";
import ConnectToQuickBooksButton from "./UI/ConnectToQuickBooksButton";
import ErrorPane from "./UI/ErrorPane";
import Section from "./UI/Section";
import LabelledInput from "./UI/LabelledInput";
import Button from "./UI/Button";
import useAuth from "../hooks/useAuth";
import useCompanies from "../hooks/useCompanies";
import useQuery from "../hooks/useQuery";
import check from "check-types";
import queryString from "query-string";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { HARDWIRED_URL } from "../config/api-client";

const AVAILABLE_FORMATS = ["json", "csv", "tra", "ecr", "sage.fec.txt"];
const AVAILABLE_TOOLS = [
  {
    value: "ACD_CSV",
    label: "ACD (CSV)"
  },
  {
    value: "CEGID_QUADRA",
    label: "Cegid Quadra (TRA)"
  },
  {
    value: "CEGID_EXPERT",
    label: "Cegid Expert (TRA)"
  },
  /*
  // Replacing two options for IBIZA by a single one
  {
    value: "IBIZA",
    label: "Fulll / Ibiza (CSV)"
  },
  {
    value: "IBIZA_FEC",
    label: "Fulll / Ibiza (FEC)"
  },
  */
  {
    value: "IBIZA",
    label: "Fulll / Ibiza (FEC)"
  },
  {
    value: "ISACOMPTA_CONNECT",
    label: "Agiris IsaCompta Connect (DEX/ECR)"
  },
  {
    value: "SAGE_GENERATION_EXPERT",
    label: "Sage Generation Expert (TXT)"
  },
  {
    value: "SAGE_GENERATION_EXPERT_RB",
    label: "Sage Generation Expert (CSV avec images - expérimental)"
  },
  {
    value: "GENERIC_CSV",
    label: "Export FEC générique (CSV)"
  }
];

const isDateValid = date => /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date);
const isFormatValid = format => AVAILABLE_FORMATS.includes(format);
const isToolValid = tool => AVAILABLE_TOOLS.map(o => o.value).includes(tool);

const AddCompanyButton = props => {
  return <Button {...props}>Add company</Button>;
};

const CompanyLine = ({ company, onSelect }) => {
  const [deleteHovered, setDeleteHovered] = useState(false);

  const bgColor = deleteHovered ? "danger" : "info";

  return (
    <Div
      w="100%"
      d="flex"
      flexDir="row"
      m={{ x: "1rem", y: "0.5rem" }}
      rounded="lg"
      bg={bgColor + "200"}
      hoverBg={bgColor + "300"}
      textSize="paragraph"
      textWeight="500"
      align="center"
      justify="center"
      cursor="pointer"
    >
      <Div
        flexGrow="1"
        d="flex"
        flexDir="row"
        align="center"
        onClick={onSelect}
        p="1rem"
      >
        {company.name}
      </Div>
      <Icon
        onMouseEnter={() => setDeleteHovered(true)}
        onMouseLeave={() => setDeleteHovered(false)}
        m={{ l: "2rem", r: "1rem" }}
        name="Cross"
        size="20px"
        onClick={() => company.disconnect().catch(console.error)}
      />
    </Div>
  );
};

const CompanySelectorWidget = ({
  selectedCompany,
  companies,
  onChange = () => {}
}) => {
  const { t } = useTranslation("CompanySelectorWidget");
  const { login } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const addCompany = login;

  if (!check.nonEmptyArray(companies)) {
    return null;
  }

  if (!menuOpen) {
    return (
      <Section title={t("Your company")}>
        <Div d="flex">
          <Div
            w="100%"
            d="flex"
            flexDir="row"
            m={{ x: "0.5rem", y: "0.5rem" }}
            rounded="lg"
            bg={"gray400"}
            textSize="paragraph"
            textWeight="500"
            align="center"
            justify="center"
          >
            <Div flexGrow="1" d="flex" flexDir="row" align="center" p="1rem">
              {selectedCompany.name}
            </Div>
            <Icon
              m={{ l: "2rem", r: "1rem" }}
              name="Edit"
              size="20px"
              onClick={() => setMenuOpen(true)}
              cursor="pointer"
            />
          </Div>
        </Div>
      </Section>
    );
  }

  const triggerAddCompanyWorkflow = () => {
    const query = queryString.parse(window.location.search) || {};

    window.location.href =
      HARDWIRED_URL +
      "/auth?" +
      queryString.stringify({
        ...(query || {}),
        from: window.location.origin + window.location.pathname,
        authError: undefined
      });
  };

  return (
    <Section title={t("Select a company")}>
      <Div
        d="flex"
        flexDir="column"
        align="center"
        bg="gray400"
        p={{ x: "1rem", y: "0.5rem" }}
        rounded="lg"
      >
        {companies.map(c => (
          <CompanyLine
            key={c.realmId}
            company={c}
            onSelect={() => onChange(c.realmId)}
          />
        ))}

        <Div
          w="100%"
          d="flex"
          flexDir="row"
          m={{ x: "1rem", y: "0.5rem" }}
          textSize="paragraph"
          textWeight="400"
          align="center"
          justify="flex-end"
        >
          <Text style={{ fontStyle: "italic" }} textColor="black700">
            {t("Connect another company:")}
          </Text>
          <ConnectToQuickBooksButton
            onClick={triggerAddCompanyWorkflow}
            m={{ l: "1rem" }}
          />
        </Div>
      </Div>
    </Section>
  );
};

export default function WelcomePage() {
  const { t } = useTranslation("WelcomePage");
  const history = useHistory();
  const { user, login } = useAuth();

  const query = useQuery();
  const {
    startDate: queryStartDate,
    endDate: queryEndDate,
    format: queryFormat,
    tool: queryTool,
    authError,
    realmId,
    clientFileId: queryClientFileId = "",
    onlyClientFileId = false,
    auxiliaryTypeValue = "qbo",
    includeAttachments: inclAttchmentsQuery = true,
  } = query;

  const [companies, selectedCompany] = useCompanies(realmId);
  console.log("Selected company: ", selectedCompany);
  const [startDate, setStartDate] = useState(queryStartDate || "2021-01-01");
  const [endDate, setEndDate] = useState(
    queryEndDate || moment().format("YYYY-MM-DD")
  );
  const [format /* setFormat */] = useState(queryFormat || null);
  const [tool, setTool] = useState(queryTool || null);
  const [includeAttachments, setIncludeAttachments] = useState(inclAttchmentsQuery === true || (check.nonEmptyString(inclAttchmentsQuery) && inclAttchmentsQuery.toLocaleLowerCase() === 'true'));
  const [convertAttachmentsToPdfs, setConvertAttachmentsToPdfs] = useState(
    false
  );
  const [clientFileId, setClientFileId] = useState(queryClientFileId);

  const isPdfForced = () => {
    return (
      tool === "SAGE_GENERATION_EXPERT" || tool === "SAGE_GENERATION_EXPERT_RB" || tool === "IBIZA"
    );
  };

  const getForcePdfValue = () => {
    return (isPdfForced() || convertAttachmentsToPdfs) && includeAttachments;
  };

  const exportUrlQuery = {
    startDate,
    endDate,
    format:
      !check.nonEmptyString(tool) && check.nonEmptyString(format)
        ? format
        : undefined,
    tool: check.nonEmptyString(tool) ? tool : undefined,
    includeAttachments:
      tool === "SAGE_GENERATION_EXPERT_RB" ? false : includeAttachments,
    convertAttachmentsToPdfs: getForcePdfValue(),
    realmId: realmId || (selectedCompany ? selectedCompany.realmId : undefined),
    clientFileId: check.nonEmptyString(clientFileId) ? clientFileId : undefined,
    auxiliaryTypeValue
  };

  const exportUrl = "/export?" + queryString.stringify(exportUrlQuery);

  const reAuthAndExport = () => {
    window.location.href =
      HARDWIRED_URL +
      "/auth?" +
      queryString.stringify({
        ...exportUrlQuery,
        from: window.location.origin + "/export",
        authError: undefined
      });
  };

  const startExport = () => {
    if (!check.nonEmptyArray(companies)) {
      reAuthAndExport();
    } else {
      history.push(exportUrl);
    }
  };

  const authErrorText = (
    <Trans t={t}>
      There was an error loging you in.
      <br />
      Please try again
    </Trans>
  );
  const errorPaneTitle = t("Login error");

  if (!user) {
    return (
      <PageLayout>
        {authError && (
          <Div p="1.5rem">
            <ErrorPane title={errorPaneTitle}>{authErrorText}</ErrorPane>
          </Div>
        )}

        <Div
          flexGrow="1"
          d="flex"
          align="center"
          justify="center"
          flexDir="column"
        >
          <Text
            textSize="display1"
            textWeight="600"
            textColor="black900"
            textAlign="center"
            m={{ b: "0.5rem" }}
          >
            {t("welcomeTitle")}
          </Text>
          <Text
            textSize="subheader"
            textWeight="400"
            textColor="black900"
            textAlign="center"
            m={{ b: "3rem" }}
          >
            <Trans t={t}>
              We'll help you export your
              <br />
              QuickBooks transactions to any format
            </Trans>
          </Text>
          <SignInWithIntuitButton onClick={login} />
        </Div>
      </PageLayout>
    );
  }

  const invalidDateError = t("Dates should use YYYY-MM-DD format");

  return (
    <PageLayout align="center" justify="flex-start">
      <Div w="100%" maxW="800px" d="flex" flexDir="column">
        <Text
          textSize="subheader"
          textWeight="600"
          textColor="black900"
          m={{ x: "1rem" }}
        >
          {t("Configure your export")}:
        </Text>

        {!onlyClientFileId && (
          <>
            <CompanySelectorWidget
              companies={companies}
              selectedCompany={selectedCompany}
              onChange={newRealmId => {
                window.location.search = queryString.stringify({
                  ...query,
                  realmId: newRealmId
                });
              }}
            />
          </>
        )}

        {(!onlyClientFileId ||
          !isDateValid(startDate) ||
          !isDateValid(endDate)) && (
          <Section title={t("Date range")}>
            <LabelledInput
              label={t("From")}
              error={!isDateValid(startDate) ? invalidDateError : null}
              placeholder="2021-01-01"
              value={startDate}
              onChange={e => {
                const { value } = e.target;
                if (value === "" || /^[0-9-]+$/.test(value)) {
                  setStartDate(e.target.value);
                }
              }}
            />
            <LabelledInput
              label={t("To")}
              error={!isDateValid(endDate) ? invalidDateError : null}
              placeholder="2021-12-31"
              value={endDate}
              onChange={e => {
                const { value } = e.target;
                if (value === "" || /^[0-9-]+$/.test(value)) {
                  setEndDate(e.target.value);
                }
              }}
            />
          </Section>
        )}

        {(!onlyClientFileId ||
          (!isFormatValid(format) && !isToolValid(tool))) && (
          <>
            <Section title={t("Export to")}>
              <Select
                options={AVAILABLE_TOOLS}
                defaultValue={AVAILABLE_TOOLS.find(t => t.value === tool)}
                isClearable={false}
                name={"format"}
                onChange={selected => {
                  const { value } = selected;
                  setTool(value);
                }}
              />
            </Section>

            {/*
                <Section title="Export format">
                  <Select
                    options={AVAILABLE_FORMATS.map(o => ({
                      value: o,
                      label: o.toUpperCase()
                    }))}
                    defaultValue={format}
                    isClearable={false}
                    name={"format"}
                    onChange={selected => {
                      const { value } = selected;
                      setFormat(value);
                    }}
                  />
                </Section>
                */}
          </>
        )}

        {tool === "ISACOMPTA_CONNECT" && (
          <Section
            title={t("Name of client file within IsaCompta (1-7 characters)")}
          >
            <Input
              value={clientFileId}
              placeholder={t("Name of client file")}
              onChange={e => {
                const val = e.target.value;

                if (/^[0-9A-Za-z_-]{0,7}$/.test(val)) {
                  setClientFileId(val);
                }
              }}
            />
          </Section>
        )}

        {!onlyClientFileId && (
          <>
            <Section title={t("Options")}>
              <Label
                onClick={() => setIncludeAttachments(!includeAttachments)}
                align="center"
                textWeight="600"
                m={{ b: "1rem" }}
              >
                <Switch checked={includeAttachments} />
                {t("Include attachments")}
              </Label>
              <Label
                onClick={() =>
                  setConvertAttachmentsToPdfs(!convertAttachmentsToPdfs)
                }
                align="center"
                textWeight="600"
                m={{ b: "1rem" }}
                style={isPdfForced() ? { filter: "opacity(50%)" } : {}}
              >
                <Switch disabled={isPdfForced()} checked={getForcePdfValue()} />
                {t("Convert all attachments to PDF")}
              </Label>
            </Section>
          </>
        )}
        <Button
          m="1rem"
          icon="Play"
          disabled={
            !isDateValid(startDate) ||
            !isDateValid(endDate) ||
            (!isFormatValid(format) && !isToolValid(tool)) ||
            (tool === "ISACOMPTA_CONNECT" &&
              !check.nonEmptyString(clientFileId))
          }
          onClick={startExport}
        >
          {t("Start export")}
        </Button>
      </Div>
    </PageLayout>
  );
}
