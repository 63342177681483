import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as StyletronProvider } from "styletron-react";
import { ThemeProvider, StyleReset, DefaultTheme } from "atomize";
import { Client as Styletron } from "styletron-engine-atomic";
import AuthProvider from "./components/AuthProvider";
import App from "./App";
import LoadingPage from "./components/LoadingPage";

// Load i18n engine
import i18n from "./lib/i18n";

const debug = false;
const engine = new Styletron();
const theme = {
  ...DefaultTheme,
  colors: {
    info350: "#CCE6FF",
    info750: "#0C66FF",
    info850: "#0D55CF",
    brand100: "#DBD6F3",
    danger350: "#FFC2C2",
    success350: "#E1EBDA",
    success550: "#92ECCF",
    success750: "#0BB07B",
    danger750: "#BD0303",
    danger950: "#DD2727"
  },
  rounded: {
    brandRadius: "20px"
  }
};

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <StyletronProvider value={engine} debug={debug} debugAfterHydration>
        <StyleReset />
        <Router>
          <AuthProvider>
            <Suspense fallback={<LoadingPage />}>
              <App />
            </Suspense>
          </AuthProvider>
        </Router>
      </StyletronProvider>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);
