import { useState, useEffect } from "react";
import firebase from "../lib/firebase";
import useAuth from "./useAuth";

const useUserData = () => {
  const { user } = useAuth();
  const [dataLoading, setDataLoading] = useState(!!user);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Work only if user is connected
    if (!user || !user.uid) {
      console.log("Cannot load data: user not present");
      return;
    }

    // If connected, subscribe to Firebase data
    const onDataChange = snap => {
      setData(snap ? snap.val() : null);
      setDataLoading(false);

      console.log("On data change: ", snap ? snap.val() : null);
    };

    // Mount
    setDataLoading(true);
    firebase
      .database()
      .ref("/users/" + user.uid)
      .on("value", onDataChange);

    // Unmount function
    const unmount = () => {
      firebase
        .database()
        .ref("/users/" + user.uid)
        .off("value", onDataChange);
    };

    return unmount;
  }, [user]);

  return [dataLoading ? null : data, dataLoading];
};

export default useUserData;
