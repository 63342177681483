import React from "react";
import { Col, Text, Input } from "atomize";

export default function LabelledInput({ label, error, ...otherProps }) {
  return (
    <Col>
      <Text
        tag="label"
        textSize="paragraph"
        textWeight="500"
        textColor="black400"
      >
        {label}
      </Text>
      <Input
        h="2.5rem"
        border={error ? "solid 1.5px" : "solid 1px"}
        borderColor={error ? "danger800" : "gray500"}
        {...otherProps}
      />
      {error && (
        <Text
          tag="label"
          textSize="caption"
          textWeight="500"
          textColor="danger800"
        >
          <span style={{ whiteSpace: "pre-wrap" }}>{error || ""}</span>
        </Text>
      )}
    </Col>
  );
}
