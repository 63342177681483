import React from "react";
import Pane from "./Pane";

export default function ErrorPane({ children, ...otherProps }) {
  return (
    <Pane color="danger" icon="AlertSolid" {...otherProps}>
      {children}
    </Pane>
  );
}
